import React from "react";
import { useGlobalContext } from "./context/globalContext";
import "../index.css"

const AboutDeveloper = () => {
  const { handleShowModal } = useGlobalContext();
  return (
    <section className="section shadow-sm lazyloaded" id="developer">
      <div className="d-block pt-2 pb-1 text-center">
        <img
          src="images/bhandup/kalpataru-logo.webp"
          width="auto"
          height="100%"
          loading="lazy"
          alt="kalpataru"
        />
      </div>
      <div className="row">
        <div className="col-md-8">
          <h1 class="d-block section-heading color-primary text-capitalize">
            About Developer
          </h1>
        </div>
        <div className="col-md-4">
          <button
            className="btn btn-info micro-form-btn enqModal effetMoveGradient effectScale download_brochure float-lg-right d-none d-lg-block"
            onClick={handleShowModal}
          >
            Chat with us
          </button>
        </div>
      </div>

      <p>
        Kalpataru Group established in the year 1969, Kalpataru Group is one of
        the leading real estate development companies in India. It has
        successfully developed many landmark projects and has set new benchmarks
        in the real estate industry. The group follows a unique approach wherein
        it focuses on creating residential and commercial spaces that offer
        maximum value to the customers.
      </p>

      <div class="rera-numbers">
        <div class="rera-item">
          <img
            src="images/bhandup/kalpataru-magnus-P51800004029.webp"
            alt="rera qr"
          />
          <strong>P51800004029</strong>
        </div>
      </div>

      <span className="pro-rera text-center-mob">
        Kalpataru Magnus has been registered via MahaRERA registration number
        and is available on the website{" "}
        <a href="https://maharera.mahaonline.gov.in/">
          https://maharera.mahaonline.gov.in/
        </a>{" "}
        under registered projects.
      </span>

      <p></p>
      <button
        className="btn btn-info micro-form-btn enqModal effetMoveGradient effectScale download_brochure float-lg-right mx-sm-auto d-lg-none"
        data-form="lg"
        data-title=" Chat with us"
        data-btn=" Chat with us"
        data-enquiry="Download Brochure Left Panel"
        data-redirect="enquiry"
        data-toggle="modal"
        data-target="#enqModal"
      >
        Chat with us
      </button>
    </section>
  );
};

export default AboutDeveloper;