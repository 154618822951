/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { useGlobalContext } from "./context/globalContext";

const Pricing = () => {
  const { handleShowModal } = useGlobalContext();
  return (
    <section className="section shadow-sm lazyloaded" id="pricing">
      <span className="section-link"></span>
      <h2 className="head text-capitalize">
        Indicative Pricing For kalpataru Magnus
      </h2>
      <div className="row">
        <div className="col-md-8">
          <table className="table table-striped table-borderless border micro-price-table table-pricing">
            <thead>
              <tr>
                <th scope="col" className="border border-bottom-0 mb-w">
                  Type
                </th>
                <th scope="col" className="border border-bottom-0 mb-w">
                  Rera Carpet Area
                </th>
                {/* <th
                  scope="col"
                  className="border border-bottom-0 border-right-0"
                >
                  10th Floor : All in
                </th> */}
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-left-0 border-top-0 border-bottom-0 price-type">
                  2 BHK
                </td>
                <td className="border border-left-0 border-top-0 border-bottom-0 price-carpet">
                  925
                  <small className="d-inline-block d-md-none">
                    (Rera Carpet Area)
                  </small>
                </td>
                <td className="price-amt">
                  <button
                    class="btn btn-sm btn-info effetGradient effectScale enqModal"
                    onClick={handleShowModal}
                  >
                    Price Breakup
                  </button>
                </td>
              </tr>
              <tr>
                <td className="border border-left-0 border-top-0 border-bottom-0 price-type">
                  2 BHK
                </td>
                <td className="border border-left-0 border-top-0 border-bottom-0 price-carpet">
                  1000
                  <small className="d-inline-block d-md-none">
                    (Rera Carpet Area)
                  </small>
                </td>
                <td className="price-amt">
                  <button
                    class="btn btn-sm btn-info effetGradient effectScale enqModal"
                    onClick={handleShowModal}
                  >
                    Price Breakup
                  </button>
                </td>
              </tr>
              <tr>
                <td className="border border-left-0 border-top-0 border-bottom-0 price-type">
                  3 BHK
                </td>
                <td className="border border-left-0 border-top-0 border-bottom-0 price-carpet">
                  1121
                  <small className="d-inline-block d-md-none">
                    (Rera Carpet Area)
                  </small>
                </td>
                <td className="price-amt">
                  <button
                    class="btn btn-sm btn-info effetGradient effectScale enqModal"
                    onClick={handleShowModal}
                  >
                    Price Breakup
                  </button>
                </td>
              </tr>
              <tr>
                <td className="border border-left-0 border-top-0 border-bottom-0 price-type">
                  3 BHK
                </td>
                <td className="border border-left-0 border-top-0 border-bottom-0 price-carpet">
                  1374
                  <small className="d-inline-block d-md-none">
                    (Rera Carpet Area)
                  </small>
                </td>
                <td className="price-amt">
                  <button
                    class="btn btn-sm btn-info effetGradient effectScale enqModal"
                    onClick={handleShowModal}
                  >
                    Price Breakup
                  </button>
                </td>
              </tr>
              <tr>
                <td className="border border-left-0 border-top-0 border-bottom-0 price-type">
                  4 BHK
                </td>
                <td className="border border-left-0 border-top-0 border-bottom-0 price-carpet">
                  1657
                  <small className="d-inline-block d-md-none">
                    (Rera Carpet Area)
                  </small>
                </td>
                <td className="price-amt">
                  <button
                    class="btn btn-sm btn-info effetGradient effectScale enqModal"
                    onClick={handleShowModal}
                  >
                    Price Breakup
                  </button>
                </td>
              </tr>
              <tr>
                <td className="border border-left-0 border-top-0 border-bottom-0 price-type">
                  4.5 BHK
                </td>
                <td className="border border-left-0 border-top-0 border-bottom-0 price-carpet">
                  1800
                  <small className="d-inline-block d-md-none">
                    (Rera Carpet Area)
                  </small>
                </td>
                <td className="price-amt">
                  <button
                    class="btn btn-sm btn-info effetGradient effectScale enqModal"
                    onClick={handleShowModal}
                  >
                    Price Breakup
                  </button>
                </td>
              </tr>
              <tr>
                <td className="border border-left-0 border-top-0 border-bottom-0 price-type">
                  4.5 BHK
                </td>
                <td className="border border-left-0 border-top-0 border-bottom-0 price-carpet">
                  1945
                  <small className="d-inline-block d-md-none">
                    (Rera Carpet Area)
                  </small>
                </td>
                <td className="price-amt">
                  <button
                    class="btn btn-sm btn-info effetGradient effectScale enqModal"
                    onClick={handleShowModal}
                  >
                    Price Breakup
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
